import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AddressAutofill } from "@mapbox/search-js-react";
import { Spacer } from "../components/Spacer";
import { InputDS } from "./Inputs/Input";

interface AddressFormProps {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  postalCode: string;
  country: string;
}

interface AddressProps {
  label: string;
  addressForm: AddressFormProps;
  onChange: (address: keyof AddressFormProps, value: string) => void;
  error?: boolean;
  onKeyDown?: (e: string) => void;
}

export const AddressFormDS = (props: AddressProps) => {
  const { t } = useTranslation();
  const accessToken = import.meta.env["VITE_MAPBOX_TOKEN"] as string;

  return (
    <form>
      <AddressAutofill accessToken={accessToken || ""}>
        <InputDS
          label={props.label}
          placeholder={t("address.placeholder.addressLine1") || ""}
          onChange={(e) =>
            props.onChange(
              "addressLine1" as keyof AddressFormProps,
              e.target.value,
            )
          }
          value={props.addressForm.addressLine1}
          error={
            !props.addressForm?.addressLine1 && props.error
              ? t("address.error.input") || ""
              : undefined
          }
          autoComplete="address-line1"
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
          withoutSpacerBottom
        />
      </AddressAutofill>
      <InputDS
        placeholder={t("address.placeholder.addressLine2") || ""}
        onChange={(e) => props.onChange("addressLine2", e.target.value)}
        value={
          props.addressForm.addressLine2 ? props.addressForm.addressLine2 : ""
        }
        autoComplete="address-line2"
        onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
        withoutSpacerBottom
      />
      <StyledCityAndPostalCode>
        <InputDS
          placeholder={t("address.placeholder.postalCode") || ""}
          onChange={(e) => props.onChange("postalCode", e.target.value)}
          value={props.addressForm.postalCode}
          error={
            !props.addressForm?.postalCode && props.error
              ? t("address.error.input") || ""
              : undefined
          }
          autoComplete="postal-code"
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
          withoutSpacerBottom
        />
        <Spacer x={0.5} />
        <InputDS
          placeholder={t("address.placeholder.city") || ""}
          onChange={(e) => props.onChange("city", e.target.value)}
          value={props.addressForm.city}
          error={
            !props.addressForm?.city && props.error
              ? t("address.error.input") || ""
              : undefined
          }
          autoComplete="address-level2"
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
          withoutSpacerBottom
        />
      </StyledCityAndPostalCode>
      <StyledCountry>
        <InputDS
          value={t("address.country.france") + " 🇫🇷"}
          //autoComplete="country"
          disabled={true}
        />
        <Spacer x={0.25} />
      </StyledCountry>
    </form>
  );
};
const StyledCityAndPostalCode = styled.div`
  display: flex;
`;
const StyledCountry = styled.div`
  display: flex;
  width: 50%;
`;
